import './FullPageResume.css'

import React, { useState } from "react";
import growers from "../../../assets/growers.png";
import binghamton from "../../../assets/binghamton.png";
import bae from "../../../assets/bae.png";
import intel from "../../../assets/intel.png";
import { ResumeCard } from '../../../components/ResumeCard/ResumeCard';

interface FullPageResumeProps {
    styles?: any,
    setHoverPosition: (x: number) => void,
}

export const FullPageResume = React.forwardRef((props: FullPageResumeProps, ref: any) => {
    const updateHoverPosition = () => {
        props.setHoverPosition(3);
    }

    return (
        <div className='FullPageResumeContainer' ref={ref} onMouseOver={updateHoverPosition}>
            <div style={{}} >
                {/* <Image src={skillsBackground} ref={ref} style={{ width: '100%', height: '70%', marginTop: '8%' }} /> */}
                <div className="CenteredTitle">
                    <p className="AboutMeSubtitle">Get to know</p>
                    <p className="AboutMeTitle">MY TIMELINE</p>
                </div>
                <div style={{ flexDirection: 'column', display: 'flex', width: '80vw', alignContent: 'center', flexWrap: 'wrap' }}>
                    <div style={{ flexDirection: 'row', flex: 1, display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ flex: 1, flexBasis: 300, paddingLeft: 10, paddingRight: 10 }}>
                            <ResumeCard
                                jobTitle='Full Stack Web Developer'
                                company='Growers Ag'
                                date='Feb 2021 - May 2022'
                                jobDescription='Developed frontend and backend web / mobile application for agricultural technology startup. Stack built using 
Node/Express, React/React Native, and SQL/Sequelize. Work broadly across frontend and backend developing new 
features and design system components, refactoring code, and resolving bugs'
                                image={growers}
                                imageHeight={'27px'}
                                imageWidth={'30px'}
                                borderColor={'lightGreen'}
                            />
                        </div>
                        <div style={{ flex: 1, flexBasis: 300, paddingLeft: 10, paddingRight: 10 }}>
                            <ResumeCard
                                jobTitle='Software Engineer II'
                                company='BAE Systems' date='Oct 2016 -  Sept 2020'
                                jobDescription='Developed frontend/backend APIs for the BAE Systems proprietary Interface Control Document (ICD) tools for automated 
code generation and test verification using Javascript/ SQL Server/ NodeJs/ HTML5 and CSS. Additionally worked with python for Interface Control Document(ICD) data manipulation and for automated code generation using 
SQL Server, pyodbc, and several other python data processing libraries'
                                image={bae}
                                imageHeight={'25px'}
                                imageWidth={'30px'}
                                borderColor={'lightGreen'}
                            />
                        </div>

                    </div>
                    <div style={{ flexDirection: 'row', flex: 1, display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ flex: 1, flexBasis: 300, paddingLeft: 10, paddingRight: 10 }}>
                            <ResumeCard jobTitle='Linux Kernel Developer'
                                company='Intel'
                                date='Summer 2015'
                                jobDescription='Wrote and submitted patches for the Linux kernel and Linux UEFI Validation Project (LUV). Patches focused on reducing the 
size of the Linux kernel by reducing the amount of zero padding through the implementation of a smaller page alignment.'
                                image={intel}
                                imageHeight={'25px'}
                                imageWidth={'35px'}
                                borderColor={'lightGreen'}
                            />
                        </div>
                        <div style={{ flex: 1, flexBasis: 300, paddingLeft: 10, paddingRight: 10 }}>
                            <ResumeCard
                                jobTitle='Graduate/Undergraduate Student'
                                company='Binghamton University' date='Sept 2011 - May 2016'
                                jobDescription='Graduated with honors as a Master of Science in Computer and Electrical Engineering'
                                image={binghamton}
                                imageHeight={'35px'}
                                imageWidth={'35px'}
                                borderColor={'lightGreen'}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </div >
    );
});