import { FunctionComponent, useRef } from "react";
import sample from '../../assets/starscape.mp4';
import { Image } from 'react-bootstrap';
import JoseProfile from '../../assets/profile_armsCrossed.png';
import './AnimatedMatrixCircle.css';

interface AnimatedMatrixCircleProps {
    styles?: any,
}

export const AnimatedMatrixCircle: FunctionComponent<AnimatedMatrixCircleProps> = ({ styles }) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    const setPlayBack = () => {
        if (videoRef.current) videoRef.current.playbackRate = .7;
    };

    return (
        <div style={{ justifyContent: 'center', paddingTop: 130, display: 'flex', zIndex: 1 }}>
            <video ref={videoRef} onCanPlay={() => setPlayBack()} muted loop autoPlay className="VideoMatrixCircle">
                <source src={sample} type="video/mp4" />
            </video>
            <Image src={JoseProfile} style={{ height: 748, zIndex: 5, position: 'absolute', top: 170, }} />
        </div>
    );
}