import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import GamesSelect from "./Games_Select";

class Games extends Component {
  constructor(props) {
    super(props);

    this.state = { bgcolor: props.bgcolor, page: "select" };
  }

  changeState = (state) => {
    this.setState({page: state});
  }

  render() {
    const gameContainer = {
      justifyContent: 'center',
      minHeight: 600,
      minWidth: "60%",
      maxWidth: "100%",
      margin: "auto",
      backgroundColor: "white",
    };

    return (
      <div style={{ backgroundColor: this.state.bgcolor, paddingBottom: 0, marginTop: 0 }}>
        <Button style={{position: 'relative', top: 40, right: "-90%", zIndex: 10,}} variant="danger" onClick={()=> this.changeState('select')}>Menu</Button>
        <div style={gameContainer}>
          <GamesSelect page={this.state.page} changeState={this.changeState}></GamesSelect>
        </div>
        <br></br>
      </div>
    );
  }
}

export default Games;
