import { FunctionComponent } from "react";
import "./FullPage.css"
import { AnimatedContactButton } from "../../components/AnimatedContactButton/AnimatedContactButton";
import { FullPageAbout } from "../../pages/FullPage/FullPageAbout/FullPageAbout";
import React from "react";
import { FullPageHome } from "../../pages/FullPage/FullPageHome/FullPageHome";
import { FullPageSkills } from "../../pages/FullPage/FullPageSkills/FullPageSkills";
import { FullPageContact } from "../../pages/FullPage/FullPageContact/FullPageContact";
import { FullPageResume } from "../../pages/FullPage/FullPageResume/FullPageResume";
import { FullPagePortfolio } from "./FullPagePortfolio/FullPagePortfolio";

interface FullPageProps {
    styles?: any,
    setHoverPosition: (x: number) => void,
    pageRefs: React.MutableRefObject<any>[],
}

export const FullPage: FunctionComponent<FullPageProps> = ({ styles, pageRefs, setHoverPosition }) => {
    return (
        <div className="FullPageContainer" style={styles}>
            <div className="AnimatedContactmeButtonContainer">
                <AnimatedContactButton ref={pageRefs[5]} />
            </div>
            <FullPageHome ref={pageRefs[0]} setHoverPosition={setHoverPosition} />
            <FullPageAbout ref={pageRefs[1]} contactMeRef={pageRefs[5]} setHoverPosition={setHoverPosition} />
            <FullPageSkills ref={pageRefs[2]} setHoverPosition={setHoverPosition} />
            <FullPageResume ref={pageRefs[3]} setHoverPosition={setHoverPosition} />
            <FullPagePortfolio ref={pageRefs[4]} setHoverPosition={setHoverPosition} />
            <FullPageContact ref={pageRefs[5]} setHoverPosition={setHoverPosition} />

        </div >
    );
}