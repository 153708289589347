import React from "react";
import { FunctionComponent } from "react";
import "./AnimatedContactButton.css"

interface AnimatedContactButtonProps {
    styles?: any,
    label?: string,
    selected?: boolean,
}

export const AnimatedContactButton = React.forwardRef((
    props: AnimatedContactButtonProps,
    ref: any
) => {
    const jumpTo = () => {
        ref.current.scrollIntoView();
    }

    return (
        <div className={"AnimatedContactButtonContainer"} onClick={jumpTo} >
            <p> Contact Me </p>
        </div>
    );
});