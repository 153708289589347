import { ThemeColors } from "../../../constants/ThemeColors";
import './FullPageAbout.css'
import React, { FunctionComponent, ReactNode } from "react";

interface AboutCardsProps {
    title: string,
    description: string,
    children: ReactNode,
}

// add a context for the sidebar being toggled off so that you can set the flexBasis of the style to 100% when this is the case

export const AboutCards: FunctionComponent<AboutCardsProps> = ({ title, description, children }) => {
    return (
        <div style={{ backgroundColor: "#8ad98c" }} className="AboutCardsContainer">
            {children}
            <p style={{ color: 'black' }} className="CardTitle">{title}</p>
            <p style={{ color: 'black' }}>{description}</p>
        </div>
    );
}
