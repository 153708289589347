import React from "react";
import { ThemeColors } from "../../../constants/ThemeColors";
import { GiGraduateCap } from "react-icons/gi"
import { IoIosRibbon } from "react-icons/io"
import ProfileColage from "../../../assets/profile_colage.png";
import { Button, Image } from "react-bootstrap";
import { AboutCards } from "./AboutCards";
import './FullPageAbout.css';

interface FullPageAboutProps {
    styles?: any,
    contactMeRef: any,
    setHoverPosition: (x: number) => void,
}

export const FullPageAbout = React.forwardRef((props: FullPageAboutProps, ref: any) => {
    const jumpTo = () => {
        props.contactMeRef.current.scrollIntoView();
    }

    const updateHoverPosition = () => {
        props.setHoverPosition(1);
    }

    return (
        <div className="FullPageAboutContainer" style={{}} ref={ref} onMouseEnter={updateHoverPosition}>
            <div className="FullPageAboutHeaderContainer">
                <p className="AboutMeSubtitle">Get to know</p>
                <p className="AboutMeTitle">About me</p>
            </div>
            <div className="FullPageAboutContentContainer">
                <div style={{ flex: 1, justifyContent: 'center', display: 'flex' }}>
                    <Image className="FullPageAboutPicture" src={ProfileColage} />
                </div>
                <div className="FullPageAboutContent">
                    <div style={{ flex: 3, display: 'flex' }}>
                        <AboutCards title={"Experience"} description={"5+ years developing software across various stacks and languages"}>
                            <IoIosRibbon style={{ color: "#198754" }} className="AboutCardIcon" />
                        </AboutCards>
                        <AboutCards title={"Education"} description={"Masters Degree in Computer Engineering"}>
                            <GiGraduateCap style={{ color: "#198754" }} className="AboutCardIcon" />
                        </AboutCards>
                        <AboutCards title={"Goals"} description={"Development of excellent frontend user experiences and high quality backend architecture"}>
                            <GiGraduateCap style={{ color: "#198754" }} className="AboutCardIcon" />
                        </AboutCards>
                    </div>
                    <div style={{ flex: 2, marginTop: '20px', padding: '0px 20px 0px 10px' }}>
                        <p>
                            I am passionate about software development and fullstack projects. Currently specialized in React, Node and Express stacks with either SQL or NoSQL databases.
                            I'm looking for opportunities to hone and expand my skills, so if you need someone with a fullstack background with excellent engineering and problem solving skills,
                            feel free to reach out.
                        </p>
                        <Button variant={"success"} onClick={jumpTo}>
                            Lets Talk
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
});