import './FullPagePortfolio.css'

import React from "react";
import Games from '../../Games/Games';

interface FullPagePortfolioProps {
    styles?: any,
    setHoverPosition: (x: number) => void,
}

export const FullPagePortfolio = React.forwardRef((props: FullPagePortfolioProps, ref: any) => {
    const updateHoverPosition = () => {
        props.setHoverPosition(4);
    }

    return (
        <div className='FullPagePortfolioContainer' ref={ref} onMouseEnter={updateHoverPosition}>
            <Games></Games>
        </div >
    );
});