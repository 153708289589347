import { FunctionComponent, useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import "./AnimatedProgressBar.css"

interface AnimatedProgressBarProps {
    styles?: any,
    label: string,
    beginProgress: boolean,
    width?: number | string,
    height?: number | string,
    max?: number,
}

export const AnimatedProgressBar: FunctionComponent<AnimatedProgressBarProps> = ({
    styles,
    label,
    beginProgress,
    width = 300,
    height = 30,
    max = 70,
}) => {
    const [count, setCount] = useState(0);

    /***
     * Resets loading state when toggling selected stat
     */
    useEffect(() => {
        if (beginProgress) return;
        setCount(0);
        cleanProgressBar();
    }, [beginProgress])

    /***
     * Controls the progress bar loading state
     */
    useEffect(() => {
        if (!beginProgress) {
            return;
        };

        const myInterval = setInterval(() => {
            setCount((oc) => {
                if (oc >= max) clearInterval(myInterval);
                return (Math.min(oc + 10, max));
            });
        }, 100);

        return () => clearInterval(myInterval);
    }, [beginProgress])

    const cleanProgressBar = () => {
        if (beginProgress) return;
        setCount(0);
    }

    return (
        <ProgressBar
            variant="success"
            label={
                <p style={{ marginLeft: 10, marginTop: 3, marginRight: 10 }}>
                    {count > max - 10 && <span className="ProgressBarText" style={{ float: 'left' }}>{label}</span>}
                    <span className="ProgressBarText" style={{ float: 'right' }}>{count} %</span>
                </p>
            }
            striped={false}
            style={styles}
            max={100}
            now={count}

        />
    );
}