import { FunctionComponent, useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import "./AnimatedButton.css"

interface AnimatedButtonProps {
    styles?: any,
    label: string,
    selected?: boolean,
    onClick?: () => void,
}

export const AnimatedButton: FunctionComponent<AnimatedButtonProps> = ({
    styles,
    label,
    selected,
    onClick,
}) => {
    const [startProgressBar, setStartProgressBar] = useState<boolean>(false);
    const [count, setCount] = useState(selected ? 100 : 0);

    useEffect(() => {
        setCount(selected ? 100 : 0);
    }, [selected])

    /***
     * Resets loading state when toggling selected stat
     */
    useEffect(() => {
        if (selected) return;
        setCount(0);
        setStartProgressBar(false);
    }, [selected])

    /***
     * Controls the progress bar loading state
     */
    useEffect(() => {
        if (!startProgressBar || selected) {
            return;
        };

        const myInterval = setInterval(() => {
            setCount((oc) => {
                if (oc > 100) clearInterval(myInterval);
                return (oc + 30);
            });
        }, 100);

        return () => clearInterval(myInterval);
    }, [startProgressBar])

    const cleanProgressBar = () => {
        if (selected) return;
        setCount(0);
        setStartProgressBar(false);
    }

    return (
        <div
            className="AnimatedButtonContainer"
            onClick={onClick}
            onMouseEnter={() => setStartProgressBar(true)}
            onMouseLeave={() => cleanProgressBar()}
            style={styles}
        >
            <p className={selected ? "AnimatedButtonTextSelected" : "AnimatedButtonText"}>{label}</p>
            <ProgressBar
                variant="warning"
                animated
                now={count}
                style={{ width: selected ? 70 : 40, height: 4 }}
            />
        </div>
    );
}