import React, { useCallback, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { SidePanel } from './SidePanel/SidePanel';
import { FullPage } from './FullPage/FullPage';
import useWindowDimensions from '../hooks/useWindowDimensions';

export const Main = () => {
    const { height, width } = useWindowDimensions();
    const SIDE_BAR_VISIBLE = width < 770 ? false : true;
    const FULL_PAGE_WIDTH_ONLY = width;
    const FULL_PAGE_WIDTH_SIDEBAR = width - 250;
    const homeRef = React.useRef<any>(null);
    const aboutMeRef = React.useRef<any>(null);
    const skillsRef = React.useRef<any>(null);
    const resumeRef = React.useRef<any>(null);
    const portfolioRef = React.useRef<any>(null);
    const contactMeRef = React.useRef<any>(null);
    const [hoverPage, setHoverPage] = useState<number>(0);

    const updateHoverPosition = useCallback(
        (pagePosition: number) => {
            setHoverPage(pagePosition)
        },
        [],
    );

    const fullPageRefs = [homeRef, aboutMeRef, skillsRef, resumeRef, portfolioRef, contactMeRef];

    return (
        <div>
            <Collapse in={SIDE_BAR_VISIBLE} mountOnEnter unmountOnExit>
                <SidePanel pageRefs={fullPageRefs} hoverPage={hoverPage} />
            </Collapse>
            <div style={{ width: SIDE_BAR_VISIBLE ? FULL_PAGE_WIDTH_SIDEBAR : FULL_PAGE_WIDTH_ONLY, float: 'right' }}>
                <FullPage pageRefs={fullPageRefs} setHoverPosition={updateHoverPosition} />
            </div>
        </div>
    );
}