import React from "react";
import { Image } from "react-bootstrap";
import ProfilePictureFace from "../../assets/profile_face.png";
import "./ProfileCircle.css"

interface FullPageProps {
    styles?: any,
}

export const ProfileCircle = React.forwardRef((props: FullPageProps, ref: any) => {
    const jumpTo = () => {
        ref.current.scrollIntoView();
    }

    return (
        <div className="ProfileCircleContainer" style={props.styles} onClick={jumpTo}>
            <Image className="ProfileCircle" src={ProfilePictureFace} />
        </div>
    );
});