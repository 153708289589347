import { Main } from './pages/Main';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    console.log("**********************************************");
    console.log("*****            Welcome to            *******");
    console.log("*****      my professional website        ****");
    console.log("*****          - Jose Duque              *****");
    console.log("**********************************************");
    console.log("*****      Built entirely with         *******");
    console.log("*****         React/ Bootstrap         *******");
    console.log("**********************************************");
  }, [])


  return (
    <Main></Main>
  );
}

export default App;
