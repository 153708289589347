import './FullPageContact.css'

import React, { useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ContactCard } from '../../../components/ContactCard/ContactCard';
import { BsFillTelephoneFill } from "react-icons/bs"
import { MdEmail } from "react-icons/md"
import { AiFillClockCircle } from "react-icons/ai"
import { ThemeColors } from "../../../constants/ThemeColors";
import { Toast } from 'react-bootstrap';

interface FullPageContactProps {
    styles?: any,
    setHoverPosition: (x: number) => void,
}

export const FullPageContact = React.forwardRef((props: FullPageContactProps, ref: any) => {
    const [userName, setUserName] = useState('');
    const [userEmail, setEmail] = useState('');
    const [userMessage, setMessage] = useState('');
    const [showA, setShowA] = useState(false);
    const [showB, setShowB] = useState(false);

    const toggleShowA = () => setShowA(!showA);
    const toggleShowB = () => setShowB(!showB);

    const updateHoverPosition = () => {
        props.setHoverPosition(5);
    }

    const form = useRef<HTMLFormElement>(null);

    const sendEmail = (e: any) => {
        e.preventDefault();

        emailjs.sendForm('service_z144tyc', 'joses_template', e.target, 'Zxq4dzXJ3n9XzdW1t')
            .then((result) => {
                console.log(result.text);
                toggleShowA();
                setUserName('');
                setEmail('');
                setMessage('');
            }, (error) => {
                console.log(error.text);
                toggleShowB();
            });
        e.target.reset();
    };

    return (
        <div className='FullPageContactContainer' ref={ref} onMouseEnter={updateHoverPosition}>
            <div style={{ width: '90%' }}>
                <div className="CenteredTitle">
                    <p className="AboutMeSubtitle">Get to know</p>
                    <p className="AboutMeTitle">CONTACT ME</p>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 40 }}>
                    <div style={{ flex: 6, marginRight: 30 }}>
                        <ContactCard title={"joseduquesoftware@gmail.com"} >
                            <MdEmail style={{ color: '#8AD98C' }} className="Icon" />
                        </ContactCard>
                        <ContactCard title={"+1 (845) 803 6402"} >
                            <BsFillTelephoneFill style={{ color: '#8AD98C' }} className="Icon" />
                        </ContactCard>
                        <ContactCard title={"8:00am - 7:00pm EST"} >
                            <AiFillClockCircle style={{ color: '#8AD98C' }} className="Icon" />
                        </ContactCard>
                    </div>
                    <Form ref={form} style={{ flex: 5 }} onSubmit={sendEmail} >
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control type="text" name={"userName"} style={{ marginBottom: 20, marginTop: 10 }} placeholder="Your Name" value={userName} onChange={(cur) => setUserName(cur.target.value)} />
                            <Form.Control type="email" name={"userEmail"} style={{ marginBottom: 20 }} placeholder="Your Email" value={userEmail} onChange={(cur) => setEmail(cur.target.value)} />
                            <Form.Control as="textarea" name={"userMessage"} style={{ marginBottom: 20 }} rows={10} placeholder="Hello Jose..." value={userMessage} onChange={(cur) => setMessage(cur.target.value)} />
                            <Button variant="success" type="submit">
                                Send Message
                            </Button>
                        </Form.Group>
                        <Toast show={showA} onClose={toggleShowA}>
                            <Toast.Header>
                                <strong className="me-auto">Message Successfully Sent!</strong>
                            </Toast.Header>
                            <Toast.Body>Thanks for messaging me! I will respond to it as soon as possible. </Toast.Body>
                        </Toast>
                        <Toast show={showB} onClose={toggleShowB}>
                            <Toast.Header>
                                <strong className="me-auto">Error sending message</strong>
                            </Toast.Header>
                            <Toast.Body>Sorry there seems to be an error sending your message. Please try contacting me directly through email or over phone. </Toast.Body>
                        </Toast>
                    </Form>
                </div>


            </div>

        </div >
    );
});