import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { AnimatedButton, SocialMediaButtons, ProfileCircle } from '../../components';
import "./SidePanel.css";
import sample from '../../assets/starscape.mp4';
import leetcode from "../../assets/leetcode.png";
import linkedin from "../../assets/linkedin.png";
import github from "../../assets/github.png";
import facebook from "../../assets/facebook.svg";

interface SidePanelProps {
    styles?: any,
    pageRefs: React.MutableRefObject<any>[],
    hoverPage: number,
}

export const SidePanel: FunctionComponent<SidePanelProps> = ({ styles, pageRefs, hoverPage }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [selectedIdx, setSelectedIdx] = useState(hoverPage);

    useEffect(() => {
        setSelectedIdx(hoverPage);
    }, [hoverPage])


    const setPlayBack = () => {
        if (videoRef.current) videoRef.current.playbackRate = .7;
    };

    const onScrollTo = (idx: number) => {
        setSelectedIdx(idx);
        if (pageRefs[idx].current) pageRefs[idx].current.scrollIntoView();
    }

    return (
        <div>
            <video ref={videoRef} onCanPlay={() => setPlayBack()} muted loop autoPlay
                className='VideoBackground'>
                <source src={sample} type="video/mp4" />
            </video>
            <div className='SidePanelContainer' style={{ zIndex: 3 }}>
                <ProfileCircle ref={pageRefs[0]} />
                <p className='SidePanelHeader'>Jose Duque</p>
                <p className='SidePanelText'>Fullstack Web Developer</p>
                <div className='SocialMediaButtonGroup' >
                    <SocialMediaButtons src={leetcode} hyperlink={'https://leetcode.com/josepro98/'} />
                    <SocialMediaButtons src={linkedin} hyperlink={'https://www.linkedin.com/in/jose-duque-engineer/'} />
                    <SocialMediaButtons src={github} hyperlink={'https://github.com/joseDuque16'} />
                    <SocialMediaButtons src={facebook} hyperlink={'https://www.facebook.com/jose.a.duque'} />

                </div>
                <div className='AnimatedButtonGroup'>
                    <AnimatedButton selected={selectedIdx === 0} label={"Home"} onClick={() => onScrollTo(0)} />
                    <AnimatedButton selected={selectedIdx === 1} label={"About"} onClick={() => onScrollTo(1)} />
                    <AnimatedButton selected={selectedIdx === 2} label={"Skills"} onClick={() => onScrollTo(2)} />
                    <AnimatedButton selected={selectedIdx === 3} label={"Resume"} onClick={() => onScrollTo(3)} />
                    <AnimatedButton selected={selectedIdx === 4} label={"Portfolio"} onClick={() => onScrollTo(4)} />
                    <AnimatedButton selected={selectedIdx === 5} label={"Contact"} onClick={() => onScrollTo(5)} />
                </div>
            </div>
        </div>

    )
}