import { FunctionComponent } from "react";
import { SocialMediaButtons } from "../SocialMediaButtons/SocialMediaButtons";


interface ResumeCardProps {
    jobTitle: string,
    company: string,
    jobDescription: string,
    date: string,
    image: string,
    style?: any,
    imageHeight?: string,
    imageWidth?: string,
    borderColor?: string,
}

const ResumeCardTitle = (jobTitle: string, company: string, jobDescription: string, date: string) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ backgroundColor: 'lightGray', paddingLeft: 10, paddingRight: 10, marginBottom: 0, width: 'fit-content', height: 'fit-content' }}>
                <p style={{ display: 'inline' }}>
                    {date}
                </p>
            </div>
            <p style={{ marginBottom: 0 }}>
                <span style={{ fontSize: 20, fontWeight: 700, marginTop: 0 }}>{jobTitle + ' - '}</span>
                <span style={{ fontSize: 19, color: "gray", fontWeight: 800, marginTop: 0 }}>{company}</span>
            </p>
            <p style={{ display: 'inline', textAlign: 'start', paddingTop: 0, marginTop: 10 }}>
                {jobDescription}
            </p>

        </div>

    )
}

export const ResumeCard: FunctionComponent<ResumeCardProps> = ({
    jobTitle,
    company,
    date,
    jobDescription,
    image,
    imageHeight = '10px',
    imageWidth = '30px',
    borderColor = '', }) => {
    return (
        <div style={{ flexDirection: 'row', display: 'flex', flex: 1, paddingTop: 50, paddingBottom: 30 }}>
            <div style={{ paddingTop: 0, paddingRight: 10 }}>
                <SocialMediaButtons
                    variant={"normal"}
                    src={image}
                    width={'45px'}
                    height={'45px'}
                    imageWidth={imageWidth}
                    imageHeight={imageHeight}
                    borderColor={borderColor} />

            </div>
            {ResumeCardTitle(jobTitle, company, jobDescription, date)}
        </div>
    )
}