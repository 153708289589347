/********************************************************************** */
//           SHAPE TYPES AND POSITIONS
// arr (top left = 0,0), bottom right = 19,10
/********************************************************************** */
// T shape:
// [0,0]
// [1,0],[1,1]
// [2,0]
export const t_shape = [
  [1, 5],
  [0, 5],
  [2, 5],
  [1, 6],
];

// L shape:
// [0,0]
// [1,0]
// [2,0],[2,1]
export const L_shape = [
  [1, 5],
  [0, 5],
  [2, 5],
  [2, 6],
];

// l shape:
// [0,0]
// [1,0]
// [2,0]
// [3,0]
export const l_shape = [
  [2, 5],
  [0, 5],
  [1, 5],
  [3, 5],
];

// square shape
// [0,-1],[0,0]
// [1,-1],[1,0]
export const square_shape = [
  [0, 4],
  [0, 5],
  [1, 4],
  [1, 5],
];

// z shape
// [0,0]
// [1,0],[1,1]
//       [2,1]
export const z_shape = [
  [1, 5],
  [0, 5],
  [1, 6],
  [2, 6],
];
