import { SocialMediaButtons } from "../../../components/SocialMediaButtons/SocialMediaButtons";
import './FullPageHome.css';
import node from "../../../assets/node.png";
import react from "../../../assets/react.png";
import javascript from "../../../assets/javascript.png";
import { AnimatedMatrixCircle } from "../../../components/AnimatedMatrixCircle/AnimatedMatrixCircle";
import React, { useEffect } from "react";
import { MdWavingHand } from 'react-icons/md'

interface FullPageHomeProps {
    styles?: any,
    setHoverPosition: (x: number) => void,
}

const GreetingCard = (title: string, subtitle: string) => {
    return (
        <div style={{ backgroundColor: "white", width: '250px', borderRadius: 10, }}>
            <div style={{ display: 'flex', flexDirection: 'row', }}>
                <div style={{ paddingLeft: 30, paddingRight: 30 }}>
                    <MdWavingHand style={{ color: 'darkGreen' }} className="Icon" />
                </div>
                <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <p style={{ display: 'block', marginTop: 0, marginBottom: 0 }}>{title} </p>
                    <h3 style={{ display: 'block' }}>{subtitle}</h3>
                </div>
            </div>
        </div>
    );
}

const ProfessionCard = (title: string, subtitle: string) => {
    return (
        <div style={{ backgroundColor: "white", width: 'fit-content', borderRadius: 10, position: 'relative', top: 40, left: 60 }}>
            <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'right' }}>
                <div style={{ paddingTop: 15, paddingBottom: 10, paddingLeft: 20, paddingRight: 20, fontSize: 17, fontWeight: 500 }}>
                    <p style={{ display: 'block', marginTop: 0, marginBottom: 0 }}>{title} </p>
                    <p style={{ display: 'block' }}>{subtitle}</p>
                </div>
            </div>
        </div>
    );
}

export const FullPageHome = React.forwardRef((props: FullPageHomeProps, ref: any) => {

    const updateHoverPosition = () => {
        props.setHoverPosition(0);
    }

    return (
        <div className={'FullPageHomeContainer'} ref={ref} onMouseEnter={updateHoverPosition}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 1 }} />
                <div style={{ flex: 1 }}>
                    <AnimatedMatrixCircle />
                    <div style={{ position: 'relative', right: '28%', top: -500, flex: 1, zIndex: 1 }}>
                        {GreetingCard("Hello, I'm", "Jose")}
                        {ProfessionCard("Fullstack Developer", "Engineer")}
                    </div>
                </div>
                <div style={{ flex: 1 }}>

                    <div style={{ position: 'relative', right: 30, top: 70, zIndex: 3 }}>
                        <SocialMediaButtons variant={"normal"} src={node} width={'130px'} height={'130px'} imageWidth={'120px'} imageHeight={'120px'} />
                    </div>
                    <div style={{ position: 'relative', right: -60, top: 170, zIndex: 3, width: 200 }}>
                        <SocialMediaButtons variant={"normal"} src={react} width={'200px'} height={'200px'} imageWidth={'140px'} imageHeight={'150px'} />
                    </div>
                    <div style={{ position: 'relative', right: 30, top: 230, zIndex: 3 }}>
                        <SocialMediaButtons variant={"normal"} src={javascript} width={'130px'} height={'130px'} imageWidth={'80px'} imageHeight={'80px'} />
                    </div>
                </div>
            </div>
        </div>
    );
});