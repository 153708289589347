import { FunctionComponent } from "react";
import { Image } from "react-bootstrap";
import "./SocialMediaButtons.css";

interface SocialMediaButtonProps {
    width?: string,
    height?: string,
    src: string,
    imageWidth?: string,
    imageHeight?: string,
    variant?: "social-media" | "normal";
    innerColor?: string,
    borderColor?: string,
    hyperlink?: string,
}

export const SocialMediaButtons: FunctionComponent<SocialMediaButtonProps> = ({
    width = '30px',
    height = '30px',
    src,
    imageWidth,
    imageHeight,
    innerColor = '',
    borderColor = '',
    variant = "social-media",
    hyperlink
}) => {
    const innerImageWidth = imageWidth ?? 22;
    const innerImageHeight = imageHeight ?? 22;

    return (
        <div className={variant === "normal" ? "NormalButtons" : "SocialMediaButtons"}
            style={{
                width: width, height: height, backgroundColor: innerColor, borderColor: borderColor,
            }}>
            <a href={hyperlink}>
                <Image src={src} style={{ width: innerImageWidth, height: innerImageHeight }} />
            </a>
        </div>
    );
}