import './FullPageSkills.css';
import { Image } from "react-bootstrap";
import skillsBackground from '../../../assets/skillsPage.png'
import ProfilePictureFace from "../../../assets/ProfilePictureFace.png";
import './FullPageSkills.css'

import React, { useState } from "react";
import { AnimatedProgressBar } from '../../../components/AnimatedProgressBar/AnimatedProgressBar';

interface FullPageSkillsProps {
    styles?: any,
    setHoverPosition: (x: number) => void,
}

export const FullPageSkills = React.forwardRef((props: FullPageSkillsProps, ref: any) => {
    const [startProgress, setStartProgress] = useState(false);

    const updateHoverPosition = () => {
        props.setHoverPosition(2);
        setStartProgress(true);
    }

    return (
        <div className='FullPageSkillsContainer' ref={ref}
            onMouseEnter={updateHoverPosition}
            onMouseLeave={() => setStartProgress(false)}
        >
            <div className='GlassContainer' >
                {/* <Image src={skillsBackground} ref={ref} style={{ width: '100%', height: '70%', marginTop: '8%' }} /> */}
                <div className="FullPageSkillstHeaderContainer">
                    <p className="AboutMeSubtitle">Get to know</p>
                    <p className="AboutMeTitle">MY SKILLS</p>
                </div>

                <div style={{ flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', display: 'flex' }}>
                    <AnimatedProgressBar styles={{ flex: 1, display: 'block', height: 30, margin: '20px 20px 10px 20px', minWidth: 300 }} max={87} label='Javascript' beginProgress={startProgress} />
                    <AnimatedProgressBar styles={{ flex: 1, display: 'block', height: 30, margin: '20px 20px 10px 20px', marginRight: 10, marginLeft: 10, minWidth: 300 }} max={81} label='React JS' beginProgress={startProgress} />
                </div>
                <div style={{ flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', display: 'flex' }}>
                    <AnimatedProgressBar styles={{ flex: 1, display: 'block', height: 30, margin: '20px 20px 10px 20px', minWidth: 300 }} max={81} label='HTML5' beginProgress={startProgress} />
                    <AnimatedProgressBar styles={{ flex: 1, display: 'block', height: 30, margin: '20px 20px 10px 20px', marginRight: 10, marginLeft: 10, minWidth: 300 }} max={75} label='CSS3' beginProgress={startProgress} />
                </div>
                <div style={{ flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', display: 'flex' }}>
                    <AnimatedProgressBar styles={{ flex: 1, display: 'block', height: 30, margin: '20px 20px 10px 20px', minWidth: 300 }} max={70} label='Node JS' beginProgress={startProgress} />
                    <AnimatedProgressBar styles={{ flex: 1, display: 'block', height: 30, margin: '20px 20px 10px 20px', marginRight: 10, marginLeft: 10, minWidth: 300 }} max={72} label='Express JS' beginProgress={startProgress} />
                </div>
                <div style={{ flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', display: 'flex' }}>
                    <AnimatedProgressBar styles={{ flex: 1, display: 'block', height: 30, margin: '20px 20px 10px 20px', minWidth: 300 }} max={68} label='NoSQL' beginProgress={startProgress} />
                    <AnimatedProgressBar styles={{ flex: 1, display: 'block', height: 30, margin: '20px 20px 10px 20px', marginRight: 10, marginLeft: 10, minWidth: 300 }} max={64} label='SQL' beginProgress={startProgress} />
                </div>
                {/* <AnimatedProgressBar label='eww' beginProgress={startProgress} /> */}

            </div>
        </div>
    );
});