import { ThemeColors } from "../../constants/ThemeColors";
import './ContactCard.css'
import React, { FunctionComponent, ReactNode } from "react";

interface ContactCardProps {
    title: string,
    children: ReactNode,
}

// add a context for the sidebar being toggled off so that you can set the flexBasis of the style to 100% when this is the case

export const ContactCard: FunctionComponent<ContactCardProps> = ({ title, children }) => {
    return (
        <div style={{ marginTop: 10, marginBottom: 20 }}>
            <div style={{ backgroundColor: '#222222' }} className="ContactCardContainer">
                {children}
                <p style={{ color: '#C5C1C1', display: 'block' }} className="CardTitle">{title}</p>
            </div>
        </div>

    );
}
